import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const ScanGuide = ({
    show,
    handleClose,
    reduxGetWMSOrderForReturn,
    successfulFetchWMSOrderForReturn,
    errorsFetchWMSOrderForReturn,
    reduxResetWMSOrderForReturn,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        setError,
    } = useForm({
        defaultValues: {
            trackingOrReturn: '',
        }
    });

    const trackingOrReturnRef = useRef(null);

    useEffect(() => {
        if (trackingOrReturnRef.current) {
            trackingOrReturnRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (successfulFetchWMSOrderForReturn === true)
            closeModal();

        if (errorsFetchWMSOrderForReturn === true) {
            setError('trackingOrReturn', { type: 'custom', message: `Órden NO valida: ${getValues('trackingOrReturn')} ` })
            setValue('trackingOrReturn', '');
        }

    }, [successfulFetchWMSOrderForReturn, errorsFetchWMSOrderForReturn])

    const closeModal = () => {
        if(errorsFetchWMSOrderForReturn)
            reduxResetWMSOrderForReturn();
        
        handleClose();
    }

    const searchOrder = (data) => {
        reduxGetWMSOrderForReturn({
            trackingOrReturn: data.trackingOrReturn
        })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Guía pendiente de Devolución
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(searchOrder)}>
                        <div className="form-group">
                            <label className="form-label">
                                Por favor, escanea o digita la guía de devolución o la guía de la orden.
                            </label>
                            <Controller
                                control={control}
                                name="trackingOrReturn"
                                rules={{
                                    required: "Este campo es requerido"
                                }}
                                render={({ field }) => (
                                    <div className="input-group">
                                        <Form.Control
                                            {...field}
                                            ref={trackingOrReturnRef}
                                            type="text"
                                            className={`form-control form-control-user`}
                                        />
                                        {
                                            errors.trackingOrReturn &&
                                            <span className="error-message">
                                                {errors.trackingOrReturn.message}
                                            </span>
                                        }
                                    </div>
                                )}
                            />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        successfulFetchWMSOrderForReturn: state.wmsState.successfulFetchWMSOrderForReturn,
        errorsFetchWMSOrderForReturn: state.wmsState.errorsFetchWMSOrderForReturn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWMSOrderForReturn: (payload) =>
            dispatch({
                type: "FETCH_WMS_ORDER_FOR_RETURN_REQUEST",
                value: payload,
            }),
        reduxResetWMSOrderForReturn: () =>
            dispatch({
                type: "RESET_WMS_ORDER_FOR_RETURN"
            })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanGuide);